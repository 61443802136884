<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">智慧园区</p>
				<p class="subTitle">Smart Park</p>
			</div>
		</div>
		
		<div class="overall-scheme">
			<div class="text">
				<div class="line"></div>
				<p class="title">方案概述</p>
				<p class="subTitle">Overall scheme</p>
				<p class="content">面向企业园区、工业园区、产业园区、物流园区等多种类型园区，以物联网、云计算、大数据等新一代信息技术为支撑，提供包括基础设施设备管理、IT配套、服务管理等的完整解决方案，创建智能、安全、连接、节能的可持续发展园区</p>
			</div>
			
			<div class="overall-img">
				<div class="overall-cot" v-for="item of overallArr" :key="item.img">
					<img :src="item.img" alt="">
					<div class="text">
						<p v-for="item1 of item.text">
							{{item1}}
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div style="background-color: #F4F8FF;">
			<div class="overall-plan">
				<div class="text">
					<div class="line"></div>
					<p class="title">整体方案</p>
					<p class="subTitle">Overall scheme</p>
				</div>
				
				<div class="content">
					<img src="../../assets/imgs/overllplan.png" alt="">
					<div class="right">
						<p class="title">智慧园区解决方案主要解决下列问题</p>
						<p>1.提供物联网、云计算园区服务应用，解决园区入驻公司各自搭建IT及企业公共应用系统，资源浪费、效率低下、建设费用昂贵的问题</p>
						<p>2.提供园区智慧大数据及物联网管理平台，实现园区整体运营、服务管理体系的智慧化，提升园区整体服务水平</p>
						<p>3.提供智能路灯、智能WIFI、监控安防等综合解决方案，解决园区基础通信网络无缝覆盖的问题</p>
						<p>4.提供园区门户，解决园区内单位内外部交流及产业链聚合问题，提升主题园区吸引力</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="monitoring">
			<img src="../../assets/imgs/monitoring.png" alt="">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧安防监控</p>
				<p class="subTitle">Intelligent security monitoring</p>
				<p class="content">根据智慧园区的实际需求，系统将结合视频监控系统、车流统计子系统、报警系统等子系统，实现综合安防管理。所有的子系统都由综合安防的管理平台接入和整合，并实现各系统间相互关联、报警联动、统一管理、统一界面，有效提高了管理部门对整个安防系统的利用率。</p>
			</div>
		</div>
		
		<div style="background-color: #F4F8FF;">
			<div class="entrance-guard">
				<div class="text">
					<p class="title">智能门禁系统</p>
					<p class="subTitle">Smart access control system</p>
					<p class="content">智能门禁系统采用基于深度学习的人脸识别技术--进行出入口控制，安全便捷，可视化程序高。通过人脸识别自动验证、鉴别出入人员的身份，完成人员的出入控制，限制无关人员的进入，将人脸识别硬件设备与考勒管理软件相结合，无缝对接现有门禁系统，实现门禁考勤二合一轻松管理。</p>
				</div>
				
				<div class="eg-img">
					<div class="eg-content" v-for="item of egArr" :key="item.id">
						<img :src="item.img" alt="">
						<p class="title">{{item.title}}</p>
						<p class="content">{{item.content}}</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="visitors">
			<div class="content">
				<div class="left">
					<div class="left-div">
						<img :src="visArr[active].img" alt="">
					</div>
				</div>
				<div class="text">
					<p class="title">{{visArr[active].title}}</p>
					<p class="subTitle">{{visArr[active].subTitle}}</p>
					<div class="text-content">
						<p v-for="item of visArr[active].content">{{item}}</p>
					</div>
				</div>
				<div class="btns">
					<div @click="handleBtnClick('-1')" :class="['right-btn',{'active-right': active != 0}]"></div>
					<div @click="handleBtnClick('+1')" :class="['left-btn', {'active-left': active != 5}]"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				overallArr: [
					{
						img: require('@/assets/imgs/overall1.png'),
						text: [
							'提供管理水平',
							'丰富服务内涵',
							'提升品牌价值'
						]
					},
					{
						img: require('@/assets/imgs/overall2.png'),
						text: [
							'降低运营成本',
							'专注核心业务',
							'提升创新能力'
						]
					},
					{
						img: require('@/assets/imgs/overall3.png'),
						text: [
							'轻松的工作环境',
							'方便的生活环境',
							'提升工作效率'
						]
					}
				],
				egArr: [
					{
						id: '1',
						title: '智能门禁',
						content: '支持常用的门禁规则配置，集成继电器完成开门功能，支持门磁，消防，出门开关，报警等I/O输入输出',
						img: require('@/assets/imgs/eg1.png')
					},
					{
						id: '2',
						title: '精准识别',
						content: '设备采用红外光学及补光系统，对环境适应能力强，夜间仍可实现高精准识别',
						img: require('@/assets/imgs/eg2.png')
					},
					{
						id: '3',
						title: '访客管理',
						content: '0.01s极速识别，访客信息一秒快速传达，刷脸即刻开门到访，提升访客管理体验',
						img: require('@/assets/imgs/eg3.png')
					},
					{
						id: '4',
						title: '开门记录',
						content: '人员进出情况自动统计记录，支持报表导出，异常情况及时查询知晓，增强企业安防建设',
						img: require('@/assets/imgs/eg4.png')
					},
				],
				visArr: [
					{
						img: require('@/assets/imgs/vis1.png'),
						title: '智能访客系统',
						subTitle: 'Smart visitor system',
						content: [
							'物智能访客管理系统是一款针对访客管理中存在的诸多问题集“移动预约+人脸抓拍+门禁授权+电梯联动”等特色功能为一体的访客管理系统，系统能够和闸机、电梯联动，访客凭“二维码通行证”扫码出入，全程实现无卡化通行，日志实时上传云端，合理地管理外来访客，有效解决传统访客管理流程繁琐和追溯困难的问题。相对于传统访客系统繁琐的制卡、发卡、收卡等流程，东洲物联智能访客管理系统具备高效智能的特点，不仅大幅提高访客管理的工作效率，又能以人性化地管理提升服务水平，通过智能化手段保障办公大楼的安全，提高企业及机关单位的电子化访客登记水平和形象。让访客管理变得安全、便捷、高效。'
						]
					},
					{
						img: require('@/assets/imgs/vis2.png'),
						title: '智慧烟感系统 ',
						subTitle: 'Intelligent smoke sensing system',
						content: [
							'智慧烟感产品采用领先的智能烟感卫士 物联网技术，建立无线组网火灾防控系统，为 世营店 第三方视频联动 智慧楼宇提供安全信息化服务打造智慧 NB-10T基站 运利网吧 出警 城市安全物联网生态体系。产品设计选 用光电雾传感器，将烟雾报警器待机或物联网云平台 者报警异常状态实时传输，内置的无线模 各类传感器 平台运地服务 平台监控报警 块利用NB-IoT网络为用户提供无线长距离活助数据任至消防主机 低功耗的数据传输，实时将烟感所有状态地信字时代 T5语合报警 数据发送到云端后台管理服务器，实现 用户信息传论装置 业主 家人 物业保安管理 短信提醒 与智慧消防云平台报警联动。系统可接单位消防主机入智慧城市大数据平台，帮助政府决策，治理消防隐患。'
						]
					},
					{
						img: require('@/assets/imgs/vis3.png'),
						title: '智慧消防栓 ',
						subTitle: 'Intelligent fire hydrant',
						content: [
							'智能消防栓系统采用物联网技术和无线通消防栓监控中心讯技术，集实时监测，智能分析，统一管理等功能为一体，可以实现GPS定位，偷盗报警，撞倒报警，偷水报警，取水授权识别，手机APP 水压，电池电量等远程无线监控。系统通过 智能消防栓监测器对消防栓用水、撞倒、水760压、漏损进行监控，将消防栓状态、用水情压力变送 表 况等数据通过天 无线网络实时发送给监控中心 井消防栓专用取水管道 ，监控中心再通知自来水公司巡查人员进行 现场取证、制止、恢复，实现轻松高效管理。'
						]
					},
					{
						img: require('@/assets/imgs/vis4.png'),
						title: '防火检测',
						subTitle: 'Fire detection',
						content: [
							'防火门监测传感器是由无线发射模块和磁块两部分组成，无线报警信号在开阔地能传输2公里，在一般住宅中能传输1公里，信号所传输的范围与周围环境有关。使用在小区单元时，门开、门闭记录一次数据，隔一个小时上报一次数据;如果门常开达到五分钟，系统中则显示报警。当设备用于常闭式防火门和常开式逃生门时，只要门开或者门闭，系统中都会显示报警。'
						]
					},
					{
						img: require('@/assets/imgs/vis5.png'),
						title: '路边停车管理系统 ',
						subTitle: 'Roadside parking management system',
						content: [
							'基于NB-IoT地磁路边占道停车系统采用无线通信技术、物联网技术、计算机网络技术等先 NB-IoT地磁探 停车APP 手持咪表 进技术手段，实时采集各占道停车场及停放车辆 测器相关信息，通过无线基站传输至后台中心，对数据进行统一存储、处理。通过后台中心统一与前端PDA设备及相关系统进行数据交互，实现临时占道停车智能管理。通过车位检测技术，手机支停车诱导屏 NB-IoT通信基站 占道停车管理中心 付手段，提高停车收费监管力度，实现“应收尽收”，提高经济效益。'
						]
					},
					{
						img: require('@/assets/imgs/vis6.png'),
						title: '路边停车管理系统 ',
						subTitle: 'Roadside parking management system',
						content: [
							'电力报装及接入',
							'箱式变电站',
							'x套直流充电机',
							'综合监控及运营管理平台',
							'所有装置均符合国家标准'
						]
					}
				],
				active: 0
			}
		},
		
		methods: {
			handleBtnClick(val) {
				this.active = this.active + parseInt(val)
				if(this.active < 0) {
					this.active = 0
				}
				if(this.active > 5) {
					this.active = 5
				}
			}
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}
.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.overall-scheme {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 59px 225px 214px;
	position: relative;
}

.overall-scheme .text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.overall-scheme .text .line {
	width: 25px;
	height: 3px;
	background: #184BAB;
	
}

.overall-scheme .text .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 4px 0 7px;
}

.overall-scheme .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.overall-scheme .text .content {
	width: 644px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	text-align: center;
	margin-bottom: 70px;
}

.overall-img {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	bottom: -110px;
	left: 50%;
	transform: translate(-50%,0);
}

.overall-img .overall-cot  {
	background-color: #fff;
	
}

.overall-img .overall-cot:not(:last-child) {
	margin-right: 28px;
}

.overall-img .overall-cot .text p:first-child{
	margin-top: 15px;
}

.overall-img .overall-cot .text p:not(:last-child) {
	margin-bottom: 22px;
}

.overall-img .overall-cot .text p:last-child {
	margin-bottom: 30px;
}

.overall-plan {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 200px 139px 113px;
}

.overall-plan .text .line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}
.overall-plan .text .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 4px 0 5px;
}

.overall-plan .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
	margin-bottom: 70px;
}

.overall-plan .content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.overall-plan .content img {
	width: 437px;
	height: 312px;
}
.overall-plan .content .right p:not(:last-child) {
	margin-bottom: 38px;
}
.overall-plan .content .right .title{
	height: 20px;
	font-size: 20px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}
.overall-plan .content .right p:not(:first-child) {
	width: 422px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 18px;
}

.monitoring {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 143px 96px 87px;
	display: flex;
	align-items: center;
}

.monitoring img {
	width: 404px;
	height: 328px;
	margin-right: 61px;
}

.monitoring .text .line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}

.monitoring .text .title {
	height: 20px;
	font-size: 20px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 9px 0;
}

.monitoring .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
	margin-bottom: 17px;
}

.monitoring .text .content {
	width: 500px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px
}

.entrance-guard {
	padding: 47px 114px 121px;
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
}

.entrance-guard .text {
	margin-bottom: 110px;
}

.entrance-guard .text .title {
	height: 20px;
	font-size: 20px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.entrance-guard .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	margin: 7px 0 15px;
	text-transform: uppercase;
}

.entrance-guard .text .content {
	width: 750px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.eg-img {
	display: flex;
	align-items: center;
}
.eg-content {
	background-color: #fff;
	width: 231px;
	height: 291px;
	padding-top: 43px;
	box-sizing: border-box;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 4px 10px 11px 0px rgba(232,232,232,0.5000);
}
.eg-content:not(:last-child) {
	margin-right: 12px;
}

.eg-content img {
	width: 72px;
	height: 70px;
}

.eg-content .title {
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 17px 0;
}

.eg-content .content {
	width: 193px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	text-align: center;
}

.visitors {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	height: 548px;
	position: relative;
}

.visitors .content {
	width: 839px;
	height: 324px;
	background-color: #F5F5F5;
	position: absolute;
	right: 91px;
	top: 103px;
}

.visitors .content .left {
	height: 262px;
	width: 321px;
	background-color: #F4F8FF;
	position: absolute;
	left: -200px;
	bottom: 0;
}

.visitors .content .left .left-div {
	height: 262px;
	width: 321px;
	background-color: #245ECB;
	position: relative;
	left: 20px;
	top: -20px;
}

.visitors .content .left .left-div img {
	width: 266px;
	height: 208px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.visitors .text {
	position: absolute;
	top: 43px;
	left: 224px;
}

.visitors .text .title {
	height: 22px;
	font-size: 22px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.visitors .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
	margin: 5px 0 9px;
}

.visitors .text .text-content p {
	width: 542px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.visitors .text .text-content p:not(:last-child) {
	margin-bottom: 15px;
}

.visitors .btns {
	position: absolute;
	right: 58px;
	bottom: 23px;
	width: 99px;
	display: flex;
}

.visitors .btns .left-btn {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-left: 15px solid #BFBFBF;
	border-bottom: 15px solid transparent;
	cursor: pointer;
}

.visitors .btns .right-btn {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-right: 15px solid #BFBFBF;
	border-bottom: 15px solid transparent;
	margin-right: 39px;
	cursor: pointer;	
}

.active-left {
	border-left: 15px solid #1A52B8 !important;
}

.active-right {
	border-right: 15px solid #1A52B8 !important;
}

</style>